exports = module.exports = require("../../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.47.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\nsection {\n    text-align: center;\n}\n\n.index_notification_3BGM2 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: var(--tertiary);\n    color: white;\n    height: 53px;\n}\n\n.index_primary-button_Rp-y8 {\n    background-color: white;\n    color: var(--looks-secondary) !important;\n    border-radius: 8px;\n    padding: 16px;\n    text-decoration: none;\n    font-weight: 700;\n    display: inline-block;\n}\n\n.index_button_vU_zs {\n    background-color: var(--motion-tertiary);\n    color: white;\n    border-radius: 8px;\n    padding: 16px;\n    text-decoration: none;\n    display: inline-block;\n}\n\n.index_button_vU_zs:hover {\n    color: black;\n    background-color: rgb(163, 163, 163);\n}", ""]);

// exports
exports.locals = {
	"notification": "index_notification_3BGM2",
	"primary-button": "index_primary-button_Rp-y8",
	"primaryButton": "index_primary-button_Rp-y8",
	"button": "index_button_vU_zs"
};